import React from "react";
import "typeface-work-sans";
import styled from "styled-components";
import { AnimatedBox } from "../elements";
import { animated } from "react-spring";

const PBox = styled(AnimatedBox)`
  max-width: 600px;
  margin: 0 auto;
`;
const Input = styled.input`
  padding: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  border: 1px solid #0e7380; 
  border-radius: 3px;
`;

const Label = styled.label`
  text-align: left;
  margin-left: 20px;
  color: #0e7380;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const Submit = styled.input`
  padding: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 30px;
  background: #0e7380;
  border: none;
  border-radius: 3px;
  color: white;
`;
const Form = styled.form`
  padding: 20px;
`;

const TextArea = styled.textarea`
  padding: 0.5em;
  margin-left: 1em;
  margin-right: 1em;
  border: 1px solid #0e7380;
 
  border-radius: 3px;
`;

const Grid = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  grid-template-columns: 1fr;
`;

const FormFields = () => (
  <PBox>
    <Form method="post" action="#" data-netlify="true" name="contact">
      <Grid>
      </Grid>
      <input type="hidden" name="form-name" value="contact" />
      <Grid>
        <Label htmlFor="name">Name</Label>
        <Input type="text" name="name" id="name" required />
      </Grid>
      <Grid>
        <Label htmlFor="email">Email</Label>
        <Input type="text" name="email" id="email" required />
      </Grid>
      <Grid>
        <Label htmlFor="message">Message</Label>
        <TextArea name="message" id="message" rows={4} required />
      </Grid>
      <Grid>
        <Submit type="submit" value="Send Message" className="special" />
      </Grid>
    </Form>
  </PBox>
);

export default FormFields;
