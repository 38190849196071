import React from "react";
import { config, useSpring } from "react-spring";
import Layout from "../components/layout";
import { AnimatedBox } from "../elements";
import SEO from "../components/SEO";
import Form from "../components/form";

const Contact = () => {
    const pageAnimation = useSpring({
        config: config.slow,
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <Layout>
            <SEO title="Contact | Garrett"
                 desc="Reach out to me to chat about collaborating, working on a project or general questions" />
            <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
                <h1>Let's chat!</h1>
                <p>
                    Questions on working together? Curious on a certain photo's exif data? Questions regarding career advice or
                    workshops? Fill out the form below and I'll get back to you ASAP!
                </p>
                <Form/>
            </AnimatedBox>
        </Layout>
    );
};

export default Contact;
